var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "v-card",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
          attrs: { elevation: "0", width: "100%" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pr-0" },
            [
              _c(
                "v-row",
                { class: _vm.$vuetify.breakpoint.smAndDown ? "mt-3" : "" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-subtitle-1 font-weight-bold my-auto",
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "ml-1",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("scheduleTicket")) + " "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 text-caption font-weight-regular",
                          attrs: {
                            elevation: "0",
                            fab: "",
                            outlined: "",
                            width: "20",
                            height: "20",
                            color: "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.scheduledTicketData.length))]
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      class: !_vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-0 pr-4"
                        : "pr-6",
                      attrs: { cols: "12", lg: "4", md: "4", xl: "4" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "rounded-lg",
                        attrs: {
                          "prepend-inner-icon": "mdi-magnify",
                          label: _vm.$t("search"),
                          "single-line": "",
                          "hide-details": "",
                          flat: "",
                          solo: "",
                          "background-color": "grey lighten-4",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0 pa-0",
            class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
            attrs: {
              color: "primary",
              "mobile-breakpoint": "950",
              "disable-sort": "",
              "item-key": "scheduled_ticket_id",
              headers: _vm.headers,
              items: _vm.scheduledTicketData,
              "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
              search: _vm.search,
              "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
              loading:
                _vm.scheduledTicketData.length === 0 && !_vm.isTableEmpyt,
              "loading-text": _vm.$t("loading"),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.scheduled_date",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.scheduled_date) + " ")]
                  },
                },
                {
                  key: "item.type",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.type) + " ")]
                  },
                },
                {
                  key: "item.center",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.center) + " ")]
                  },
                },
                {
                  key: "item.street",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.street) + " ")]
                  },
                },
                {
                  key: "item.place",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.place) + " ")]
                  },
                },
                {
                  key: "item.county",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.county) + " ")]
                  },
                },
                {
                  key: "item.state",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.state) + " ")]
                  },
                },
                {
                  key: "item.edit",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { "aria-label": "Edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editItem(item)
                            },
                          },
                        },
                        [_vm._v(" mdi-pencil-outline ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.delete",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { "aria-label": "Delete" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(item)
                            },
                          },
                        },
                        [_vm._v(" mdi-delete-outline ")]
                      ),
                    ]
                  },
                },
                !_vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "body",
                      fn: function (ref) {
                        var items = ref.items
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c(
                                "tr",
                                {
                                  key: item.scheduled_ticket_id,
                                  staticClass: "pr-2 pb-4",
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: { height: "auto", elevation: "0" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { cols: "9" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-subtitle-1 font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.scheduled_date
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("type")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [_vm._v(_vm._s(item.type))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("centerText")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [_vm._v(" " + _vm._s(item.center))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-regular",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("address")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 text-body-2 text--darken-2 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.street) +
                                                  ", " +
                                                  _vm._s(item.place) +
                                                  ", " +
                                                  _vm._s(item.county) +
                                                  ", " +
                                                  _vm._s(item.state)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 pt-4 mb-2" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    "aria-label": "Edit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editItem(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-pencil-outline ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    "aria-label": "Delete",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-delete-outline ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "420", "max-height": "400", persistent: "" },
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("deleteScheduleTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-row", [_c("v-divider")], 1),
              _c("v-row", { staticClass: "ma-0 ml-4 mb-10 pt-4" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "grey--text text--darken-4 text-subtitle-1 font-weight-regular",
                  },
                  [_vm._v(_vm._s(_vm.$t("deleteMessage")))]
                ),
              ]),
              _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0",
                      attrs: {
                        color: "primary",
                        outlined: "",
                        depressed: "",
                        width: "90",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: { width: "90", depressed: "" },
                      on: { click: _vm.deleteScheduleTicket },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="mt-4">
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-card-title class="pr-0">
        <v-row :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''">
          <span
            class="text-subtitle-1 font-weight-bold my-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-1'"
            >{{ $t("scheduleTicket") }}
            <v-btn
              elevation="0"
              fab
              outlined
              width="20"
              height="20"
              class="ml-3 text-caption font-weight-regular"
              color="primary"
            >
              {{ scheduledTicketData.length }}</v-btn
            ></span
          >
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selected"
        color="primary"
        class="elevation-0 pa-0"
        mobile-breakpoint="950"
        disable-sort
        item-key="scheduled_ticket_id"
        :headers="headers"
        :items="scheduledTicketData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading="scheduledTicketData.length === 0 && !isTableEmpyt"
        :loading-text="$t('loading')"
      >
        <template v-slot:[`item.scheduled_date`]="{ item }">
          {{ item.scheduled_date }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type }}
        </template>
        <template v-slot:[`item.center`]="{ item }">
          {{ item.center }}
        </template>
        <template v-slot:[`item.street`]="{ item }">
          {{ item.street }}
        </template>
        <template v-slot:[`item.place`]="{ item }">
          {{ item.place }}
        </template>
        <template v-slot:[`item.county`]="{ item }">
          {{ item.county }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          {{ item.state }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon class="mr-2" aria-label="Edit" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon class="mr-2" aria-label="Delete" @click="deleteItem(item)">
            mdi-delete-outline
          </v-icon>
        </template>
        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.scheduled_ticket_id"
              class="pr-2 pb-4"
            >
              <v-card height="auto" elevation="0">
                <v-row>
                  <v-col cols="9" class="ml-1">
                    <span class="text-subtitle-1 font-weight-bold">
                      {{ item.scheduled_date }}</span
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>

                <v-row class="ml-1">
                  <v-col
                    cols="4"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("type") }}:</v-col
                  >
                  <v-col
                    class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                    >{{ item.type }}</v-col
                  >
                </v-row>
                <v-row class="ml-1"
                  ><v-col
                    cols="4"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("centerText") }}:</v-col
                  ><v-col
                    class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                  >
                    {{ item.center }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col
                    cols="4"
                    class="pa-0 text-body-2 text--darken-2 font-weight-regular"
                    >{{ $t("address") }}:</v-col
                  ><v-col
                    class="pa-0 text-body-2 text--darken-2 font-weight-medium"
                  >
                    {{ item.street }}, {{ item.place }}, {{ item.county }},
                    {{ item.state }}</v-col
                  ></v-row
                >
                <v-row class="ml-1 pt-4 mb-2">
                  <v-col cols="3" class="pa-0">
                    <v-icon
                      class="mr-3"
                      aria-label="Edit"
                      @click="editItem(item)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-icon
                      class="mr-2"
                      aria-label="Delete"
                      @click="deleteItem(item)"
                    >
                      mdi-delete-outline
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      max-width="420"
      max-height="400"
      persistent
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("deleteScheduleTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="ma-0 ml-4 mb-10 pt-4">
          <span
            class="grey--text text--darken-4 text-subtitle-1 font-weight-regular"
            >{{ $t("deleteMessage") }}</span
          >
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="deleteScheduleTicket"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
export default {
  mixins: [EditTicket],
  data() {
    return {
      scheduledTicketData: [],
      search: "",
      deleteDialog: false,
      itemToDelete: null,
      selected: [],
      isTableEmpyt: false,
      headers: [
        {
          text: "Scheduled On",
          value: "scheduled_date",
        },
        { text: "Type", value: "type" },
        { text: "Center", value: "center" },
        { text: "Street Address", value: "street" },
        { text: "Place", value: "place" },
        { text: "County", value: "county" },
        { text: "State", value: "state" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
    };
  },
  created() {
    this.moment = moment;
    this.getScheduledData();
  },
  methods: {
    async getScheduledData() {
      this.scheduledTicketData =
        this.$store.state.ScheduleTicket.scheduleTicketsData;
      const scheduledTicketData = await this.$store.dispatch(
        "getScheduleTicketData",
      );
      if (scheduledTicketData.status !== "error") {
        scheduledTicketData.sort(function (a, b) {
          return new Date(b.scheduled_date) - new Date(a.scheduled_date);
        });
        scheduledTicketData.forEach((item) => {
          item.scheduled_date = this.moment(
            new Date(item.scheduled_date),
          ).format("MM/DD/YYYY, hh:mm A");
          if (item.options && item.options.ticketData) {
            item.street = item.options.ticketData.street;
            item.place = item.options.ticketData.place;
            item.county = item.options.ticketData.county;
            item.state = item.options.ticketData.state;
          }
        });
        this.$store.commit("setScheduleTicket", scheduledTicketData);
        if (scheduledTicketData.length == 0) this.isTableEmpyt = true;
        this.scheduledTicketData = scheduledTicketData;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatTime(time) {
      if (!time) return null;
      let [hours, minute] = time.split(":");
      let AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minute} ${AmOrPm}`;
    },
    async editItem(item) {
      await this.editTicket(item, "ScheduleTicket");
      this.$emit("createTicket");
      this.setTicketData(item);
    },
    closeDialog() {
      this.deleteDialog = false;
    },
    async deleteScheduleTicket() {
      const scheduledTicketId = await this.$store.dispatch(
        "deleteScheduleTicket",
        {
          scheduled_ticket_id: this.itemToDelete.scheduled_ticket_id,
        },
      );
      this.deleteDialog = false;
      if (scheduledTicketId.error !== "error") {
        this.getScheduledData();
      }
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
  },
};
</script>

<style lang="scss" scoped></style>
